import React from "react";

import { useLocation } from "react-router-dom";

import { Button, Row, Col, ButtonToolbar, ButtonGroup } from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import OrderProductForm from "./OrderProductForm";
import OrderShippingForm from "./OrderShippingForm";
import OrderReviewForm from "./OrderReviewForm";
import OrderPaymentForm from "./OrderPaymentForm";

/*
const UPDATE_SELLER = gql`
  mutation ($id: ID!, $input: SellerUpdateInput!) {
    updateSeller(id: $id, input: $input) {
      id
    }
  }
`;
*/

const GET_PRODUCTS = gql`
  query ($id: String!) {
    products(input: { sellerId: $id }) {
      id
      sku
      name
      description
      price {
        currency
        value
      }
      images {
        url
        fileName
      }
      components {
        id
        name
        colorSections {
          id
          name
          colorOptions {
            id
            name
            hexCode
          }
        }
      }
    }
  }
`;

const ProcessStep = (props) => {
  return (
    <div
      className="d-flex px-3"
      style={{ opacity: props.currentStep < props.stepNumber ? 0.25 : 1 }}
      onClick={() => {
        props.currentStep > props.stepNumber &&
          props.currentStep != 4 &&
          props.onClick();
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "32px",
          height: "32px",
          borderStyle: "solid",
          borderColor: "#9DACFF",
          backgroundColor: "#EEF1FF",
          borderRadius: "64px",
          borderWidth: "1px",
        }}
      >
        {props.stepNumber}
      </div>
      <div className="d-flex align-items-center justify-content-center mx-1">
        {props.title}
      </div>
    </div>
  );
};

const OrderSubmitForm = (props) => {
  const userContext = React.useContext(UserContext);

  //1 - Select products
  //2 - Determine shipping
  //3 - Pay
  //4 - Confirmation/Failure
  const [step, setStep] = React.useState(1);
  const [productList, setProductList] = React.useState(null);
  const [shippingInfo, setShippingInfo] = React.useState({
    name: "",
    addressLines: "",
    postcode: "",
    country: "United States", //This is the default country for now
    state: "",
    city: "",
    phone: "",
  });
  const [lineItems, setLineItems] = React.useState([]);
  const [orderData, setOrderData] = React.useState(null);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  //TODO: Add order submit mutation
  //const [updateSeller, { data, loading, error }] = useMutation(UPDATE_SELLER);
  const productsQuery = useQuery(GET_PRODUCTS, {
    variables: { id: userContext.id },
    skip: !userContext.id,
  });

  React.useEffect(() => {
    if (productsQuery.data) {
      const products = productsQuery.data?.products;
      if (products) {
        setProductList(products);

        //Add product specified in the URL (if one exists)
        const pid = urlParams.get("withProduct");

        if (pid) {
          products.forEach((p) => {
            if (p.id === pid) {
              //Set the key to -1 because the other key counter starts at 0 :-)
              lineItems.push({ product: p, quantity: 1, key: -1 });
            }
          });
        }
      }
    }
  }, [productsQuery]);

  const handleSubmit = (event) => {
    event.preventDefault();

    //updateSeller({ firstname, lastname, company, contactEmail: email, phone });
  };

  var renderedComponent = null;

  switch (step) {
    case 1:
      renderedComponent = (
        <OrderProductForm
          lineItems={lineItems}
          updateLineItems={setLineItems}
          products={productList}
          nextStep={() => setStep(2)}
        />
      );
      break;
    case 2:
      renderedComponent = (
        <OrderShippingForm
          updateShippingInfo={setShippingInfo}
          shippingInfo={shippingInfo}
          nextStep={() => setStep(3)}
          previousStep={() => setStep(1)}
        />
      );
      break;
    case 3:
      renderedComponent = (
        <OrderReviewForm
          lineItems={lineItems}
          shippingInfo={shippingInfo}
          setOrderData={setOrderData}
          nextStep={() => setStep(4)}
          previousStep={() => setStep(3)}
        />
      );
      break;
    case 4:
      renderedComponent = (
        <OrderPaymentForm
          orderData={orderData}
          lineItems={lineItems}
          shippingInfo={shippingInfo}
        />
      );
      break;
  }

  return (
    <Col>
      <Row className="d-flex my-4 justify-content-center">
        <ProcessStep
          stepNumber={1}
          currentStep={step}
          title="Products"
          onClick={() => setStep(1)}
        />
        <ProcessStep
          stepNumber={2}
          currentStep={step}
          title="Shipping"
          onClick={() => setStep(2)}
        />
        <ProcessStep
          stepNumber={3}
          currentStep={step}
          title="Review"
          onClick={() => setStep(3)}
        />
        <ProcessStep stepNumber={4} currentStep={step} title="Payment" />
      </Row>
      <Row className="my-4 justify-content-center">{renderedComponent}</Row>
    </Col>
  );
};

export default OrderSubmitForm;
