import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import ColorBox from "./ColorBox";

const ColorSelector = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 4,
      }}
    >
      {props.options.map((color, index) => (
        <ColorBox
          name={color.name}
          hexCode={color.hexCode}
          key={index}
          onClick={() => {
            props.onChange(color.id);
          }}
          opacity={props.value == color.id ? 1 : 0.25}
        />
      ))}
    </div>
  );
};

export default ColorSelector;
