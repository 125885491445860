import React from "react";

import { useLocation } from "react-router-dom";
import loading from "../assets/loading.svg";
import bot_sad from "../assets/VulkazaBot_Sad.svg";

import { Container, Col, Row } from "reactstrap";
import Loading from "./Loading";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import reactRouterDom from "react-router-dom";

import formatToCurrency from "../utils/currentyFormatter";

const ErrorView = (props) => {
  return (
    <Container className="d-flex align-items-center flex-column">
      <Row className="mt-5">
        {props.type == "LOADING" ? (
          <div className="d-flex justify-content-center">
            <img src={loading} alt="Loading" width={150} />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img src={bot_sad} alt="Error" width="50%" />
          </div>
        )}
      </Row>

      <Row className="mt-5">
        <p className="h4 text-center">{props.title}</p>
      </Row>
      <Row className="mt-5">
        <p className="text-center">{props.message}</p>
      </Row>
    </Container>
  );
};

export default ErrorView;
