import React from "react";

import { Table, Row, Col, Button, Alert } from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import { useHistory } from "react-router-dom";

import formatToCurrency from "../utils/currentyFormatter";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

import { countryToCode } from "../utils/countryCodes";

const SUBMIT_ORDER = gql`
  mutation CreateOrder($input: OrderCreateInput!) {
    createOrder(data: $input) {
      id
      transaction {
        stripeClientSecret
      }
    }
  }
`;

//Rendered line in the table
const LineItem = (props) => {
  const orderLine = props.orderLine;

  const colorNames = orderLine.product.components.map((component) => {
    const colorId = orderLine.selectedColors[component.id];
    var name = "Unspecified Color";

    component.colorSections[0].colorOptions.forEach((color) => {
      if (color.id === colorId) {
        name = color.name;
      }
    });

    return name;
  });
  var colors = colorNames.join(", ");

  return (
    <tr>
      <td>{orderLine.product.name}</td>
      <td>{colors}</td>
      <td className="text-right">{orderLine.quantity}</td>
      <td className="text-right font-weight-bold">
        {formatToCurrency(
          orderLine.product.price.value * orderLine.quantity,
          orderLine.product.price.currency
        )}
      </td>
    </tr>
  );
};

//Main component
const OrderReviewForm = (props) => {
  const { seller, id, setSeller } = React.useContext(UserContext);

  const [submitOrder, submitOrderResult] = useMutation(SUBMIT_ORDER);

  const history = useHistory();

  const handleSubmit = (paymentOption) => {
    const vars = {
      input: {
        sellerId: id,
        lineItems: props.lineItems.map((li) => ({
          id: li.product.id,
          components: li.product.components.map((component) => {
            return {
              componentId: component.id,
              sections: [
                {
                  sectionId: component.colorSections[0].id,
                  colorId: li.selectedColors[component.id],
                },
              ],
            };
          }),
          quantity: parseInt(li.quantity),
        })),
        deliveryAddress: {
          ...props.shippingInfo,
          addressLines: props.shippingInfo.addressLines,
          country: countryToCode(props.shippingInfo.country),
        },
        paymentOption: paymentOption,
      },
    };

    submitOrder({
      variables: vars,
    });
  };

  React.useEffect(() => {
    if (submitOrderResult) {
      if (submitOrderResult.data) {
        console.log(submitOrderResult);
        //Handle Invoice payments
        if (!submitOrderResult.data.createOrder.transaction) {
          history.push("/order/" + submitOrderResult.data.createOrder.id);
        }

        //Pass the info about the created order to the parent
        props.setOrderData(submitOrderResult?.data.createOrder);
        props.nextStep();
      } else if (submitOrderResult.error) {
        console.error(submitOrderResult.error);
      }
    }
  }, [submitOrderResult]);

  //Add each registered line to the table
  const lineItemRows = props.lineItems.map((li, index) => (
    <LineItem orderLine={li} key={index} />
  ));

  var totalPrice = 5; //This is the cost of shipping.
  props.lineItems.forEach((li) => {
    totalPrice += li.quantity * li.product.price.value;
  });

  const createShippingAddressElements = (a) => {
    return (
      <Col className="mx-2">
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            Name
          </Col>
          <Col>{a.name}</Col>
        </Row>
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            Address
          </Col>
          <Col>{a.addressLines}</Col>
        </Row>
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            Country
          </Col>
          <Col>{a.country}</Col>
        </Row>
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            State
          </Col>
          <Col>{a.state ? a.state : "-"}</Col>
        </Row>
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            City
          </Col>
          <Col>{a.city}</Col>
        </Row>
        <Row>
          <Col
            className="font-weight-bold px-0 flex-grow-0"
            style={{ minWidth: "100px" }}
          >
            Phone
          </Col>
          <Col>{a.phone ? a.phone : "-"}</Col>
        </Row>
      </Col>
    );
  };

  return (
    <Col>
      <Row className="my-3">
        {createShippingAddressElements(props.shippingInfo)}
      </Row>
      <Row className="my-3">
        <Table>
          <tbody>
            {lineItemRows}
            <tr>
              <td colSpan={2} className="text-left ">
                Local Shipping (flat rate)
              </td>
              <td colSpan={1} className="text-right ">
                1
              </td>
              <td className="text-right font-weight-bold">
                {formatToCurrency(5, "USD")}
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="text-left font-weight-bold">
                Total
              </td>
              <td className="text-right font-weight-bold">
                {formatToCurrency(totalPrice, "USD")}
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <Row className="d-flex justify-content-center">
        {seller.availablePaymentOptions?.includes("Invoice") && (
          <Button
            className="mr-4"
            color="secondary"
            onClick={() => {
              handleSubmit("Invoice");
            }}
            disabled={submitOrderResult.loading}
          >
            Invoice me Later
          </Button>
        )}
        <Button
          className="ml-4"
          color="primary"
          onClick={() => {
            handleSubmit("Stripe");
          }}
          disabled={submitOrderResult.loading}
        >
          Pay now using card
        </Button>
      </Row>
      {submitOrderResult.error && (
        <Alert color="danger" className="w-100">
          Something went wrong. Please try again!
        </Alert>
      )}
    </Col>
  );
};

export default OrderReviewForm;
