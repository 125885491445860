//Adds image residing params to a GraphCMS image URL
//original looks like this: https://media.graphassets.com/xR5t0hESEe2U6FJWtwTQ
//formatted looks like this: https://media.graphassets.com/resize=fit:crop,height:300,width:300/xR5t0hESEe2U6FJWtwTQ
//
//fit = "clip", "crop", "scale" or "max"
const formatImageUrl = (
  originalUrl,
  width = 400,
  height = 400,
  fit = "crop"
) => {
  //Turn "https://media.graphassets.com/xR5t0hESEe2U6FJWtwTQ"
  //into ["https://media.graphassets", "xR5t0hESEe2U6FJWtwTQ"]
  const parts = originalUrl.split(".com/");

  //Build the middle part
  var args = `resize=fit:${fit},height:${height},width:${width}`;

  //Stitch them together and return the result
  return parts[0] + ".com/" + args + "/" + parts[1];
};

export default formatImageUrl;
