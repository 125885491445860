import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const TableHeaderSortingToggle = (props) => {
  return (
    <th
      onClick={() => {
        props.setValue(props.value === "DESC" ? "ASC" : "DESC");
      }}
    >
      {props.label}
      <FontAwesomeIcon
        className="mx-1"
        icon={props.value === "DESC" ? faCaretDown : faCaretUp}
      />
    </th>
  );
};

export default TableHeaderSortingToggle;
