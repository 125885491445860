import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

//This component listens to changes in the path and informs google that the user has entered a new page.
//Super simple tracking
const RouteChangeTracker = ({ history }) => {
  const location = useLocation();
  const [currentPath, setPath] = React.useState(location.pathname);

  history.listen((location, action) => {
    if (location.pathname != currentPath) {
      setPath(location.pathname);
    }
  });

  React.useEffect(() => {
    if (currentPath) {
      console.log(currentPath);
      ReactGA.send({ hitType: "pageview", page: currentPath });
    }
  }, [currentPath]);

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
