import React from "react";
import { Container, Row, Col } from "reactstrap";

import OrderView from "../components/OrderView";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ApolloConsumer } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import { useMutation, useQuery, gql } from "@apollo/client";

export const OrderPage = ({ match }) => {
  React.useEffect(() => (document.title = "Order - Vulkaza Dashboard"));

  const {
    params: { orderId },
  } = match;

  return (
    <Container className="my-5">
      <ApolloConsumer>
        {(client) => <OrderView client={client} orderId={orderId} />}
      </ApolloConsumer>
    </Container>
  );
};

export default withAuthenticationRequired(OrderPage, {
  onRedirecting: () => <Loading />,
});
