import React, { Fragment } from "react";

import { UserContext } from "../contexts/UserContext";

import FrontPage from "../components/FrontPage";
import CatalogueView from "../components/CatalogueView";
import SignupForm from "../components/SignupForm";
import Loading from "../components/Loading";
import ProductIdeaModal from "../components/ProductIdeaModal";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import jwt_decode from "jwt-decode";
import { ApolloConsumer } from "@apollo/client";

import { Row, Col, Alert, Button, Container, Badge } from "reactstrap";
import ErrorView from "../components/ErrorView";
import ModalBanner from "../components/ModalBanner";

export const Home = () => {
  React.useEffect(() => (document.title = "Home - Vulkaza Dashboard"));

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { id } = React.useContext(UserContext);

  const [productIdeaModalVisible, setProductIdeaModalVisible] =
    React.useState(false);

  return (
    <Fragment>
      {isAuthenticated && id && (
        <Col>
          <Row>
            <ModalBanner
              header="Can't find what you're looking for?"
              buttonText="Design your own product"
              modal={(isOpen, onToggle) => (
                <ProductIdeaModal isOpen={isOpen} onToggle={onToggle} />
              )}
            />
          </Row>
          <Row>
            <Col>
              <h1 style={{ textAlign: "center", marginTop: "32px" }}>
                Choose from unique products 3D printed on-demand
              </h1>
              <p style={{ textAlign: "center", width: "100%" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Step 1:
                </span>{" "}
                To start selling, first add products to your collection
              </p>
            </Col>
          </Row>
          <Row>
            <ApolloConsumer>
              {(client) => <CatalogueView client={client} />}
            </ApolloConsumer>
          </Row>
        </Col>
      )}
      {isAuthenticated && !id && (
        <ErrorView
          title="Logging you in..."
          message="Please wait while we log you in."
          type="LOADING"
        />
      )}
      {!isAuthenticated && <FrontPage />}
    </Fragment>
  );
};

export default Home;

/*export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Loading />,
});
*/
