import React from "react";

import logo from "../assets/logo.svg";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Col,
  Row,
} from "reactstrap";

import { codes, countryToCode } from "../utils/countryCodes";

const OrderShippingForm = (props) => {
  const [name, setName] = React.useState(props.shippingInfo.name);
  const [addressLines, setAddressLines] = React.useState(
    props.shippingInfo.addressLines
  );
  const [postcode, setPostcode] = React.useState(props.shippingInfo.postcode);
  const [country, setCountry] = React.useState(props.shippingInfo.country);
  const [state, setState] = React.useState(props.shippingInfo.state);
  const [city, setCity] = React.useState(props.shippingInfo.city);
  const [phoneNumber, setPhoneNumber] = React.useState(
    props.shippingInfo.phone
  );

  React.useEffect(() => {
    props.updateShippingInfo({
      name,
      addressLines,
      postcode,
      country,
      state,
      city,
      phoneNumber,
    });
  }, [name, addressLines, postcode, country, state, city, phoneNumber]);

  const addressValid = () => {
    return (
      name !== "" &&
      addressLines !== "" &&
      postcode !== "" &&
      country !== "" &&
      city !== ""
    );
  };

  const countryOptions = codes.map((code, index) => (
    <option key={index}>{code.label}</option>
  ));

  return (
    <Col>
      <Row className="d-flex justify-content-center text-center">
        <h2>Where should we ship this order?</h2>
      </Row>
      <Row className="my-4 w-100 justify-content-center px-3">
        <Form className="w-100" style={{ maxWidth: "500px" }}>
          <FormGroup row>
            <Label className="w-25">Name *</Label>
            <Input
              id="name"
              name="name"
              placeholder="Name of the recipient"
              type="text"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">Address Lines *</Label>
            <Input
              id="addressLines"
              name="addressLines"
              placeholder="The address of the recipient"
              type="textarea"
              rows="2"
              required
              value={addressLines}
              onChange={(e) => {
                setAddressLines(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">Postcode *</Label>
            <Input
              id="postcode"
              name="postcode"
              placeholder="The postcode of the recipient"
              type="text"
              value={postcode}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">Country *</Label>
            <Input
              id="country"
              name="country"
              type="select"
              required
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              className="w-75"
            >
              {countryOptions}
            </Input>
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">State</Label>
            <Input
              id="state"
              name="state"
              placeholder="The state the recipient resides in"
              type="text"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">City *</Label>
            <Input
              id="city"
              name="city"
              placeholder="The city the recipient resides in"
              type="text"
              required
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
          <FormGroup row>
            <Label className="w-25">Phone</Label>
            <Input
              id="phone"
              name="phone"
              placeholder="The phone number of the recipient"
              type="tel"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="w-75"
            />
          </FormGroup>
        </Form>
      </Row>
      <Row className="my-4 justify-content-center">
        <Button
          color="primary"
          onClick={() => {
            props.nextStep();
          }}
          disabled={!addressValid()}
        >
          Review Order
        </Button>
      </Row>
    </Col>
  );
};

export default OrderShippingForm;
