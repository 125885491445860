import React, { Fragment } from "react";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import ProductList from "../components/ProductList";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ApolloConsumer } from "@apollo/client";

import { Row, Col, Alert, Button, Container } from "reactstrap";

import { UserContext } from "../contexts/UserContext";

import ProductIdeaModal from "../components/ProductIdeaModal";
import ModalBanner from "../components/ModalBanner";

export const MyProducts = () => {
  React.useEffect(() => (document.title = "My Products - Vulkaza Dashboard"));

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { id } = React.useContext(UserContext);

  const [productIdeaModalVisible, setProductIdeaModalVisible] =
    React.useState(false);

  return (
    <Fragment>
      {isAuthenticated && id && (
        <Col>
          <Row>
            <ModalBanner
              header="Can't find what you're looking for?"
              buttonText="Design your own product"
              modal={(isOpen, onToggle) => (
                <ProductIdeaModal isOpen={isOpen} onToggle={onToggle} />
              )}
            />
          </Row>
          <Row>
            <Col>
              <p style={{ textAlign: "center", width: "100%", marginTop: 32 }}>
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Step 2:
                </span>{" "}
                Go to product details to download promotional images and copy
                the product description for your e-shop.
              </p>
            </Col>
          </Row>
          <Row>
            <Container>
              <ApolloConsumer>
                {(client) => <ProductList client={client} />}
              </ApolloConsumer>
            </Container>
          </Row>
        </Col>
      )}
    </Fragment>
  );
};

export default withAuthenticationRequired(MyProducts, {
  onRedirecting: () => <Loading />,
});
