import React from "react";

import { useLocation } from "react-router-dom";

import { Col, Row, Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

const FrontPage = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  if (urlParams.get("authRedirect") === "signup") {
    loginWithRedirect({
      scope: "userinfo",
      screen_hint: "signup",
    });
  }

  return (
    <Col>
      <Row className="d-flex justify-content-center my-5">
        <h1 className="text-center">Awesome products, produced locally</h1>
      </Row>
      <Row className="d-flex justify-content-center my-5">
        <Button
          id="qsLoginBtn"
          color="primary"
          className="btn-margin ml-2"
          onClick={() =>
            loginWithRedirect({
              scope: "userinfo",
            })
          }
        >
          Log in
        </Button>
        <Button
          id="qsLoginBtn"
          color="primary"
          className="btn-margin ml-2"
          onClick={() =>
            loginWithRedirect({
              scope: "userinfo",
              screen_hint: "signup",
            })
          }
        >
          Sign up
        </Button>
      </Row>
    </Col>
  );
};

export default FrontPage;
