import React from "react";

import logo from "../assets/logo.svg";

import { UserContext } from "../contexts/UserContext";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import { useMutation, gql } from "@apollo/client";

const CREATE_SELLER = gql`
  mutation ($input: SellerCreateInput!) {
    createSeller(data: $input) {
      id
    }
  }
`;

const SignupForm = (props) => {
  const { user } = useAuth0();
  const { id, setId } = React.useContext(UserContext);

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState(user.email);
  const [phone, setPhone] = React.useState("");

  const [createSeller, { data, loading, error }] = useMutation(CREATE_SELLER);

  const handleSubmit = (event) => {
    event.preventDefault();

    createSeller({
      variables: {
        input: { firstname, lastname, company, contactEmail: email, phone },
      },
    });
  };

  React.useEffect(() => {
    if (data?.createSeller?.id) {
      setId(data.createSeller.id);
    }
  }, [data]);

  return (
    <div className="d-flex align-items-center flex-column mt-5">
      <h1 className="mb-5">Complete your signup</h1>
      <Form
        onSubmit={handleSubmit}
        className="w-50"
        style={{ maxWidth: "500px" }}
      >
        <FormGroup>
          <Label>First name *</Label>
          <FormFeedback>Test</FormFeedback>
          <Input
            id="firstname"
            name="firstname"
            placeholder="Your given name"
            type="text"
            required
            value={firstname}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup>
          <Label>Last name *</Label>
          <Input
            id="lastname"
            name="lastname"
            placeholder="Your family name"
            type="text"
            required
            value={lastname}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup>
          <Label>Company</Label>
          <Input
            id="firstname"
            name="firstname"
            placeholder="The company you represent (optional)"
            type="text"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email *</Label>
          <Input
            id="firstname"
            name="firstname"
            placeholder="Your email address"
            type="email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone</Label>
          <Input
            id="firstname"
            name="firstname"
            placeholder="Your phone number (optional)"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            disabled={loading}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-center">
          <Button className="px-5 mt-4" type="submit" disabled={loading}>
            Submit
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default SignupForm;
