import React from "react";

import logo from "../assets/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Input,
} from "reactstrap";

import { VULKAZA_API_URL } from "../App";

import { generateZipDirectoryBlobFromFiles } from "../utils/fileZipper";
import { sendProductIdeaEmail } from "../utils/mailersend";

import { UserContext } from "../contexts/UserContext";

const ProductIdeaModal = (props) => {
  const { seller } = React.useContext(UserContext);

  const [ideaDescription, setIdeaDescription] = React.useState([]);
  const [isSending, setIsSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = async () => {
    /*
    const formData = new FormData();
    formData.append("name", seller ? seller.name : "No name");
    formData.append("email", seller ? seller.contactEmail : "No email");
    formData.append("description", ideaDescription);
    const res = await fetch(
      "http://localhost:3001/api/file-upload/submit-product-idea",
      {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
        },
        body: formData,
      }
    ).catch((err) => console.log(err));
    console.log(res);*/
    setErrorMessage("");
    setIsSending(true);
    const res = await fetch(
      VULKAZA_API_URL + "/api/form-submissions/submit-product-idea",
      {
        method: "POST",
        body: JSON.stringify({
          firstname: seller?.firstname,
          lastname: seller?.lastname,
          email: seller?.contactEmail,
          description: ideaDescription,
        }),
      }
    );

    if (res.error) {
      setErrorMessage("Something went wrong. Please try again!");
    } else {
      setSent(true);
    }

    setIsSending(false);
  };

  /* Code for storing and sending zip with uploaded files
  const [files, setFiles] = React.useState([]);

  const storeFiles = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const submittedFiles = Array.from(e.target.files);
    setFiles(submittedFiles);
  };

  const handleSubmit = async () => {
    if (files) {
      setIsSending(true);
      const zipBlob = await generateZipDirectoryBlobFromFiles(
        files.map((f) => {
          return { name: f.name, blob: f };
        })
      );

      var reader = new FileReader();
      reader.readAsDataURL(zipBlob);
      reader.onloadend = async () => {
        var base64data = reader.result;

        const formData = new FormData();
        formData.append("name", seller ? seller.name : "No name");
        formData.append("email", seller ? seller.name : "No email");
        formData.append("b64", base64data);
        const res = await fetch(
          "http://localhost:3001/api/file-upload/submit-product-idea",
          {
            method: "POST",
            headers: {
              Accept: "multipart/form-data",
              "Content-Type": "multipart/form-data",
            },
            body: formData,
          }
        ).catch((err) => console.log(err));
        console.log(res);
      };
    }
  };
  */

  return (
    <Modal toggle={props.onToggle} isOpen={props.isOpen}>
      <ModalHeader>Share your product idea!</ModalHeader>
      <ModalBody>
        {sent ? (
          <Col>
            <Row className="my-3">
              Thank you for sharing your idea! We will get in touch with you at{" "}
              {seller?.contactEmail} as soon as possible.
            </Row>
          </Col>
        ) : (
          <Col>
            <Row className="my-3">
              Want to turn your imagination into reality? Share your ideas with
              us and we will help you design amazing products your customers
              will love!
            </Row>
            <Row className="my-3">
              Describe your idea in detail and add relevant links to images or
              other similar products, and we will get in touch with you within
              24 hours.
            </Row>
            <Row className="my-3">
              <Input
                className="p-1"
                type="textarea"
                rows="5"
                value={ideaDescription}
                onChange={(e) => setIdeaDescription(e.target.value)}
              />
            </Row>
            {/* Removed until a server that can handle large file inputs is set up
          <Row className="my-3">
            <Input className="p-1" type="file" onChange={storeFiles} multiple />
          </Row>*/}
          </Col>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between flex-row-reverse">
        {!sent && (
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={
              ideaDescription.length == 0 || !seller?.contactEmail || isSending
            }
          >
            Submit
          </Button>
        )}
        {!sent && <Button onClick={props.onToggle}>Cancel</Button>}
        {sent && (
          <Button color="primary" onClick={props.onToggle}>
            Close
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ProductIdeaModal;
