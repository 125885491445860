import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const FloatingSavePopup = (props) => {
  return (
    <div style={style}>
      <p className="m-2">You have unsaved changes.</p>
      <div>
        <Button
          className="m-2"
          color="secondary"
          onClick={props.revertChanges}
          disabled={props.disabled}
        >
          Revert Changes
        </Button>
        <Button
          className="m-2"
          color="primary"
          onClick={props.saveChanges}
          disabled={props.disabled}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

const style = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FAD4D8",
  padding: "4px",
};

export default FloatingSavePopup;
