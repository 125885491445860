import React from "react";

import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Input, InputGroup } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import OrderList from "../components/OrderList";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ApolloConsumer } from "@apollo/client";

import { UserContext } from "../contexts/UserContext";
import { Button } from "reactstrap";

export const ProfileComponent = () => {
  React.useEffect(() => (document.title = "My Orders - Vulkaza Dashboard"));
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const [filter, setFilter] = React.useState("");

  return (
    <Container className="my-5">
      <Row className="mb-4">
        <InputGroup className="d-flex align-items-center">
          <div
            style={{
              display: "flex",
              backgroundColor: "#efefef",
              width: 32,
              height: 32,
              marginRight: 16,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 16,
            }}
            onClick={() => setFilter("")}
          >
            <FontAwesomeIcon
              icon={faEraser}
              color={filter === "" ? "#dedede" : "#000"}
            />
          </div>
          <Input
            className="mr-5"
            type="text"
            placeholder="Type anything to filter orders"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Button
            color="primary"
            onClick={() => {
              let path = `/submit-order/`;
              history.push(path);
            }}
          >
            Submit Order
          </Button>
        </InputGroup>
      </Row>
      <Row>
        <ApolloConsumer>
          {(client) => <OrderList client={client} filter={filter} />}
        </ApolloConsumer>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
