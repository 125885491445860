import React, { Component } from "react";

import placeholder from "../assets/image_placeholder.png";

import { useHistory } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery, gql } from "@apollo/client";

import contentData from "../utils/contentData";
import formatToCurrency from "../utils/currentyFormatter";
import formatImageUrl from "../utils/gcmsImageResize";

import ExpandableText from "./ExpandableText";

const DUPLICATE_PRODUCT = gql`
  mutation ($id: ID!) {
    duplicateProduct(data: { productId: $id }) {
      id
    }
  }
`;

const ProductCard = (props) => {
  const p = props.product;
  const history = useHistory();

  const [duplicateProduct, { data, loading, error }] = useMutation(
    DUPLICATE_PRODUCT,
    { variables: { id: p.id } }
  );

  const [hover, setHover] = React.useState(false);
  const [imgHover, setImgHover] = React.useState(false);

  const [image, setImage] = React.useState(0);

  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const nextImage = () => {
    if (p.images.length == 0) {
      return;
    }
    setImage((image + 1) % p.images.length);
  };

  const previousImage = () => {
    if (p.images.length == 0) {
      return;
    }
    setImage((image - 1) % p.images.length);
  };

  const openProductPage = () => {
    let path = `/product/${p.id}`;
    history.push(path);
  };

  const submitOrderWithProduct = () => {
    let path = `/submit-order/?withProduct=${p.id}`;
    history.push(path);
  };

  React.useEffect(() => {
    if (data?.duplicateProduct?.id) {
      props.onDuplicate();
    }
  }, [data]);

  var imgsrc = placeholder;
  var imgalt = "Missing image";

  if (p.images.length > 0) {
    if (imgHover && p.images.length > 1) {
      imgsrc = formatImageUrl(p.images[1].url, 400, 400);
      imgalt = p.images[1].fileName;
    } else {
      imgsrc = formatImageUrl(p.images[0].url, 400, 400);
      imgalt = p.images[0].fileName;
    }
  }

  return (
    <Card
      style={{
        borderWidth: 0,
        width: "16rem",
        backgroundColor: hover ? "#efefef" : "#fff",
        borderColor: hover ? "#cfcfcf" : "#ffffff00",
        borderWidth: 2,
      }}
      onMouseEnter={(event) => setHover(true)}
      onMouseLeave={(event) => setHover(false)}
    >
      <CardImg
        onMouseEnter={(event) => setImgHover(true)}
        onMouseLeave={(event) => setImgHover(false)}
        alt={imgalt}
        src={imgsrc}
        top
        style={{ objectFit: "cover" }}
        onClick={(event) => {
          openProductPage();
        }}
      />
      <div style={{ position: "absolute", top: 8, right: 8 }}>
        <Dropdown isOpen={menuOpen} toggle={toggleMenu} direction="start">
          <DropdownToggle
            color="transparent"
            style={{
              boxShadow: "0 0 0 0",
              margin: 4,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} size="2x" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={openProductPage}>Details</DropdownItem>
            <DropdownItem onClick={submitOrderWithProduct}>
              Submit Order
            </DropdownItem>
            {
              //<DropdownItem divider />
              //<DropdownItem onClick={openProductPage}>Archive</DropdownItem>
            }
          </DropdownMenu>
        </Dropdown>
      </div>
      <CardBody
        className="d-flex flex-column justify-content-between p-0"
        onClick={(event) => {
          openProductPage();
        }}
      >
        <CardTitle className="mx-2 mt-2 mb-0">{p.name}</CardTitle>
        <CardText className="h5 mx-2 mt-0">
          {formatToCurrency(p.price.value, p.price.currency)}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
