import React from "react";

import { Tooltip } from "reactstrap";
import { hexToBrightness, changeBrightness } from "../utils/colorMath";

const ColorBox = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [overrideMessage, setOverrideMessage] = React.useState(false);
  const [domId, setDomId] = React.useState(
    props.id ? props.id : "ColorBox_" + Math.round(Math.random() * 99999)
  );

  const toggle = () => {
    //If toggling off, reset the copy message
    if (isOpen) {
      setOverrideMessage(null);
    }
    setOpen(!isOpen);
  };

  const brightness = hexToBrightness(props.hexCode);
  const darker = changeBrightness(props.hexCode, 0.5);

  return (
    <div
      onClick={
        props.onClick
          ? props.onClick
          : () => {
              navigator.clipboard.writeText(props.hexCode);
              setOverrideMessage("Copied to clipboard!");
            }
      }
    >
      <div
        style={{
          width: 31,
          height: 31,
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: brightness < 240 ? props.hexCode : darker,
          backgroundColor: props.hexCode,
          opacity: props.opacity ? props.opacity : 1,
        }}
        id={domId}
      />
      <Tooltip isOpen={isOpen} toggle={toggle} target={domId}>
        {overrideMessage
          ? overrideMessage
          : props.name +
            (props.showHexCodeInTooltip ? " " + props.hexCode : "")}
      </Tooltip>
    </div>
  );
};

export default ColorBox;
