import React from "react";

import {
  Label,
  Input,
  Button,
  List,
  ListInlineItem,
  Row,
  Col,
  Container,
} from "reactstrap";

import { useHistory } from "react-router-dom";

import Loading from "./Loading";

import { useMutation, useQuery, gql } from "@apollo/client";
import CatalogueList from "./CatalogueList";
import { ApolloConsumer } from "@apollo/client";

//TODO: CHANGE TO CATALOGUE INSTEAD OF THE SELLERS PRODUCTS
const GET_CATALOGUE = gql`
  query {
    collections {
      id
      name
      description
      products {
        id
        sku
        name
        description
        price {
          currency
          value
        }
        images {
          url
          fileName
        }
      }
    }
  }
`;

const DUPLICATE_PRODUCT = gql`
  mutation ($id: ID!) {
    duplicateProduct(data: { productId: $id }) {
      id
    }
  }
`;

const CatalogueView = (props) => {
  const history = useHistory();
  const [collections, setCollections] = React.useState([]);

  const catalogueQuery = useQuery(GET_CATALOGUE);

  React.useEffect(() => {
    if (catalogueQuery?.data?.collections) {
      setCollections(catalogueQuery.data?.collections);
    }
  }, [catalogueQuery]);

  const [duplicateProductQuery, { data, loading, error }] =
    useMutation(DUPLICATE_PRODUCT);

  React.useEffect(() => {
    if (data?.duplicateProduct?.id) {
      let path = `/product/${data.duplicateProduct.id}`;
      history.push(path);
    }
  }, [data]);

  const duplicateProduct = (id) => {
    duplicateProductQuery({ variables: { id } });
  };

  var collectionList = collections.map((c, i) => (
    <Row className="my-4" key={i}>
      <Container
        className="px-0"
        style={{
          borderRadius: "8px",
        }}
      >
        <h2 className="mb-1 mt-3 mx-3">{c.name}</h2>
        <p className="mb-3 mt-2 mx-3">{c.description}</p>
        <CatalogueList collection={c} duplicateProduct={duplicateProduct} />
      </Container>
    </Row>
  ));

  return loading ? <Loading /> : <Col>{collectionList}</Col>;
};

export default CatalogueView;
