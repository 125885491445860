import React from "react";

import logo from "../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  Label,
  Input,
  Button,
  List,
  ListInlineItem,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import ProductCard from "./ProductCard";
import ColorSelector from "./ColorSelector";

import formatToCurrency from "../utils/currentyFormatter";

//Rendered line in the table
const LineItem = (props) => {
  const orderLine = props.orderLine;
  const multiComponent = orderLine.product.components.length > 1;

  /*

  var components;
  var colors;

  if (orderLine.product.components.length == 1) {
    components = "single layout comps";
    colors = "single layout colors";
  } else {
    components = (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>{orderLine.product.name}</div>
        </div>
        {orderLine.product.components.map((component, index) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: 16, flexGrow: 1 }} key={index}>
              {component.name}
            </div>
          </div>
        ))}
      </div>
    );

    colors = (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>...</div>
        {orderLine.product.components.map((component, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexGrow: 1,
            }}
          >
            {component.colors.map((color, index) => (
              <ColorBox
                name={color.name}
                hexCode={color.hexCode}
                key={index}
                id={"ColorBox_" + component.id + "_" + index}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }*/

  return (
    <>
      <tr>
        <td>{orderLine.product.name}</td>
        <td>
          {
            //If this product has only 1 component, show the color selection on the product level
            !multiComponent && orderLine.product.components.length > 0 && (
              <ColorSelector
                options={
                  orderLine.product.components[0]?.colorSections[0]
                    ?.colorOptions
                }
                value={
                  orderLine.selectedColors[orderLine.product.components[0].id]
                }
                onChange={(newColorId) => {
                  props.onChangeColor(
                    orderLine.key,
                    orderLine.product.components[0].id,
                    newColorId
                  );
                }}
              />
            )
          }
        </td>
        <td>
          <Input
            type="number"
            className="text-right"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (parseInt(e.target.value) > 0) {
                props.onChangeQuantity(orderLine.key, e.target.value);
              }
            }}
            value={orderLine.quantity}
          />
        </td>
        <td className="text-right font-weight-bold">
          {formatToCurrency(
            orderLine.product.price.value * orderLine.quantity,
            orderLine.product.price.currency
          )}
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            className="mr-3"
            onClick={() => props.onDelete(orderLine.key)}
          />
        </td>
      </tr>
      {
        //If this product has multiple components, show each on a separate row with a separate color selector
        multiComponent &&
          orderLine.product.components.map((component, index) => (
            <tr key={index}>
              <td style={{ borderTop: 0, paddingLeft: 32 }}>
                {component.name}
              </td>
              <td style={{ borderTop: 0 }}>
                <ColorSelector
                  options={component.colorSections[0].colorOptions}
                  value={orderLine.selectedColors[component.id]}
                  onChange={(newColorId) => {
                    props.onChangeColor(
                      orderLine.key,
                      component.id,
                      newColorId
                    );
                  }}
                />
              </td>
              <td style={{ borderTop: 0 }} colSpan={3}></td>
            </tr>
          ))
      }
    </>
  );
};

//Entry in the dropdown
const ProductEntry = (props) => {
  const p = props.product;

  return (
    <Row>
      <Col className="w-50">{p.name}</Col>
    </Row>
  );
};

//Main component
const OrderProductForm = (props) => {
  const products = props.products;

  const lineItems = props.lineItems;
  const setLineItems = props.updateLineItems;
  const [productDropdownOpen, setProductDropdownOpen] = React.useState(false);

  //Used to give each order line a unique ID (for updating quantity etc)
  const [uniqueKey, setUniqueKey] = React.useState(0);

  const addProductToOrder = (product) => {
    //Create a map between components and their selected colors
    var selectedColors = {};
    product.components.forEach((component) => {
      if (component.colorSections.length > 0) {
        if (component.colorSections[0].colorOptions.length > 0) {
          selectedColors[component.id] =
            component.colorSections[0].colorOptions[0].id;
        }
      }
    });

    lineItems.push({
      product: product,
      selectedColors: selectedColors,
      quantity: 1,
      key: uniqueKey,
    });
    setUniqueKey(uniqueKey + 1);

    setLineItems(lineItems);
  };

  const updateQuantity = (key, newQuantity) => {
    lineItems.forEach((li) => {
      if (li.key === key) {
        li.quantity = newQuantity;
      }
    });
    setLineItems([...lineItems]);
  };

  const updateColor = (key, componentId, colorId) => {
    lineItems.forEach((li) => {
      if (li.key === key) {
        li.selectedColors[componentId] = colorId;
      }
    });
    setLineItems([...lineItems]);
  };

  const deleteLine = (key) => {
    var index = -1;
    lineItems.forEach((li, i) => {
      if (li.key === key) {
        index = i;
      }
    });
    if (index != -1) {
      lineItems.splice(index, 1);
      setLineItems([...lineItems]);
    }
  };

  //Add each registered line to the table
  const lineItemRows = lineItems.map((li, index) => (
    <LineItem
      orderLine={li}
      key={index}
      onChangeQuantity={updateQuantity}
      onChangeColor={updateColor}
      onDelete={deleteLine}
    />
  ));

  var totalPrice = 0;

  lineItems.forEach((li) => {
    totalPrice += li.quantity * li.product.price.value;
  });

  var productRows = [];

  if (products) {
    if (products.length > 0) {
      productRows = products.map((p, index) => (
        <DropdownItem
          key={index}
          onClick={() => {
            addProductToOrder(p);
          }}
        >
          <ProductEntry product={p} />
        </DropdownItem>
      ));
    } else {
      productRows = <DropdownItem disabled>You have no products</DropdownItem>;
    }
  } else {
    productRows = <DropdownItem disabled>Loading...</DropdownItem>;
  }

  return (
    <Col>
      <Row className=" my-3 d-flex justify-content-center">
        <Dropdown
          isOpen={productDropdownOpen}
          toggle={() => {
            setProductDropdownOpen(!productDropdownOpen);
          }}
        >
          <DropdownToggle caret>Add Product to Order</DropdownToggle>
          <DropdownMenu>{productRows}</DropdownMenu>
        </Dropdown>
      </Row>
      <Row className="my-3">
        <Table>
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Name</th>
              <th style={{ width: "30%" }}>Color</th>
              <th className="text-right" style={{ width: "20%" }}>
                Quantity
              </th>
              <th className="text-right" style={{ width: "20%" }}>
                Cost
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {lineItemRows}
            <tr>
              <td colSpan={3} className="text-left font-weight-bold">
                Total
              </td>
              <td className="text-right font-weight-bold">
                {formatToCurrency(totalPrice, "USD")}
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
      </Row>
      <Row className="my-4 justify-content-center">
        <Button
          color="primary"
          onClick={() => {
            props.nextStep();
          }}
          disabled={lineItems.length == 0}
        >
          Continue to Shipping
        </Button>
      </Row>
    </Col>
  );
};

export default OrderProductForm;
