import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../contexts/UserContext";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const { id } = React.useContext(UserContext);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div className="nav-container">
      <div>
        <p
          style={{
            textAlign: "center",
            padding: 8,
            margin: 0,
            backgroundColor: "#9DACFF",
          }}
        >
          <span style={{ fontWeight: "bold" }}>BETA:</span> We’re just getting
          started. Please help us grow and send us your feedback to{" "}
          <a href="mailto:sales@vulkaza.com">sales@vulkaza.com.</a>
        </p>
      </div>
      <Navbar color="light" light expand="md" className="py-4 lead">
        <Container>
          <NavbarBrand>
            <img
              src="https://media.graphassets.com/JSz0SbPRTw6b2kTeFvoM?_gl=1*r2b94h*_ga*MTc3ODUzNzE2Ny4xNjQ2MjE0ODg0*_ga_G6FYGSYGZ4*MTY1NDE3MTIwMi4xNjQuMS4xNjU0MTcxMjU2LjY."
              style={{ height: "32px" }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Home
                  </NavLink>
                </NavItem>
              }
              {isAuthenticated && (
                <>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/my-collection"
                      exact
                      activeClassName="router-link-exact-active"
                      hidden={!id}
                    >
                      My Collection
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/catalogue"
                      exact
                      activeClassName="router-link-exact-active"
                      hidden={!id}
                    >
                      Catalogue
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/my-orders"
                      exact
                      activeClassName="router-link-exact-active"
                      hidden={!id}
                    >
                      Orders
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/submit-order"
                      exact
                      activeClassName="router-link-exact-active"
                      hidden={!id}
                    >
                      Submit Order
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
            <Nav className="flex-row gx-5" navbar>
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="32"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      <>
                        <DropdownItem header>{user.name}</DropdownItem>
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/profile"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                          hidden={!id}
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" />{" "}
                          Profile
                        </DropdownItem>
                      </>
                    }
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
