import React from "react";

import { Row, Col, Table } from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";

import formatToCurrency from "../utils/currentyFormatter";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const LineItem = (props) => {
  const orderLine = props.orderLine;
  return (
    <tr>
      <td>{orderLine.product.name}</td>
      <td className="text-right">{orderLine.quantity}</td>
      <td className="text-right font-weight-bold">
        {formatToCurrency(
          orderLine.product.price.value,
          orderLine.product.price.currency
        )}
      </td>
    </tr>
  );
};

//Main component
const OrderPaymentForm = (props) => {
  const clientSecret = props.orderData?.transaction?.stripeClientSecret;
  const orderId = props.orderData?.id;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: clientSecret,
    appearance,
  };

  //Add each registered line to the table
  const lineItemRows = props.lineItems.map((li, index) => (
    <LineItem orderLine={li} key={index} />
  ));

  var totalPrice = 5; //This is the cost of shipping.
  props.lineItems.forEach((li) => {
    totalPrice += li.quantity * li.product.price.value;
  });

  return clientSecret && orderId ? (
    <div>
      <h2 className="my-5">Complete your payment</h2>
      <Table className="my-5">
        <tbody>
          {lineItemRows}
          <tr>
            <td colSpan={1} className="text-left ">
              Local Shipping (flat rate)
            </td>
            <td colSpan={1} className="text-right ">
              1
            </td>
            <td className="text-right font-weight-bold">
              {formatToCurrency(5, "USD")}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-left font-weight-bold">
              Total
            </td>
            <td className="text-right font-weight-bold">
              {formatToCurrency(totalPrice, "USD")}
            </td>
          </tr>
        </tbody>
      </Table>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm orderId={orderId} />
      </Elements>
    </div>
  ) : (
    "Loading..."
  );
};

export default OrderPaymentForm;
