const hexToBrightness = (hexcode) => {
  //Validate input
  if (!hexcode || hexcode === "") {
    console.error("Invalid hexcode : " + hexcode);
    return 0;
  }

  hexcode = hexcode.toLowerCase();

  if (hexcode.charAt(0) == "#") {
    hexcode = hexcode.substring(1); // strip #
  }

  if (hexcode.length != 6) {
    console.error("Invalid hexcode : " + hexcode);
    return 0;
  }

  var rgb = parseInt(hexcode, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma;
};

const changeBrightness = (hexcode, delta) => {
  //Validate input
  if (!hexcode || hexcode === "") {
    console.error("Invalid hexcode : " + hexcode);
    return 0;
  }

  hexcode = hexcode.toLowerCase();

  if (hexcode.charAt(0) == "#") {
    hexcode = hexcode.substring(1); // strip #
  }

  if (hexcode.length != 6) {
    console.error("Invalid hexcode : " + hexcode);
    return 0;
  }

  var rgb = parseInt(hexcode, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  r = Math.min(Math.round(r * delta), 255);
  g = Math.min(Math.round(g * delta), 255);
  b = Math.min(Math.round(b * delta), 255);

  //Convert back to hex
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
};

export { hexToBrightness, changeBrightness };
