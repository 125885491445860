import { saveAs } from "file-saver";

var JSZip = require("jszip");
var JSZipUtils = require("jszip-utils");

/*  Uses https://stuk.github.io/jszip/documentation/api_jszip/file_data.html

Takes in a list of files in the format:
    [
        {
            "name": <filename>
            "url": <link to file>
        }
    ]

    ...and triggers a download action for a zip containing all the files
*/
const generateAndDownloadZipDirectoryFromURLs = (directoryName, files) => {
  //Create the directory
  var zip = new JSZip();

  //Add the files
  files.forEach((file) => {
    zip.file(file.fileName, urlToPromise(file.url), { binary: true });
  });

  //Make the zipdir
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, directoryName);
  });
};

function urlToPromise(url) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/*  Uses https://stuk.github.io/jszip/documentation/api_jszip/file_data.html

Takes in a list of files in the format:
    [
        {
            "name": <filename>
            "blob": <file blob>
        }
    ]

    ...and triggers a download action for a zip containing all the files
*/
const generateZipDirectoryBlobFromFiles = async (files) => {
  //Create the directory
  var zip = new JSZip();

  //Add the files
  files.forEach((file) => {
    zip.file(file.name, file.blob);
  });

  //Make the zipdir
  const blob = await zip.generateAsync({ type: "blob" });

  return blob;
};

export {
  generateAndDownloadZipDirectoryFromURLs,
  generateZipDirectoryBlobFromFiles,
};
