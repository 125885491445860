import React from "react";
import { Container, Row, Col } from "reactstrap";

import ProfileView from "../components/ProfileView";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ApolloConsumer } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";

export const Profile = () => {
  React.useEffect(() => (document.title = "Profile - Vulkaza Dashboard"));

  const { getAccessTokenSilently } = useAuth0();

  return (
    <Container className="my-5">
      <ApolloConsumer>
        {(client) => <ProfileView client={client} />}
      </ApolloConsumer>
    </Container>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
