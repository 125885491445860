import React from "react";

import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import MyProducts from "./views/MyProducts";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import { UserContext } from "./contexts/UserContext";

import jwt_decode from "jwt-decode";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  createHttpLink,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// styles
import "./App.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import MyOrders from "./views/MyOrders";
import SubmitOrder from "./views/SubmitOrder";
import ProductPage from "./views/ProductPage";
import OrderPage from "./views/OrderPage";
import RouteChangeTracker from "./utils/RouteChangeTracker";

initFontAwesome();

const VULKAZA_API_DEV = "https://vulkaza-service-a26bxdli7-vulkaza.vercel.app";

export const VULKAZA_API_URL = process.env.REACT_APP_VULKAZA_API_URL;

const GET_SELLER = gql`
  query ($id: ID!) {
    seller(id: $id) {
      id
      firstname
      lastname
      company
      contactEmail
      phone
      availablePaymentOptions
    }
  }
`;

const CREATE_SELLER = gql`
  mutation ($input: SellerCreateInput!) {
    createSeller(data: $input) {
      id
    }
  }
`;

const App = () => {
  const { user, isLoading, error, getAccessTokenSilently } = useAuth0();

  const [id, setId] = React.useState(null);
  const [seller, setSeller] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState(null);

  const userContext = {
    id,
    setId,
    seller,
    setSeller,
  };

  const [apolloClient, setApolloClient] = React.useState(
    new ApolloClient({
      uri: VULKAZA_API_URL + "/api/graphql",
      cache: new InMemoryCache(),
    })
  );

  const [getSeller, getSellerResult] = useLazyQuery(GET_SELLER, {
    variables: { id: id },
    client: apolloClient,
  });

  const [createdSeller, setCreateSeller] = React.useState(false);
  const [createSeller, createSellerResult] = useMutation(CREATE_SELLER, {
    client: apolloClient,
  });

  React.useEffect(() => {
    if (createSellerResult.data?.createSeller?.id) {
      setId(createSellerResult.data.createSeller.id);
    }
  }, [createSellerResult]);

  //Fetch the user when the ID is set
  React.useEffect(() => {
    if (id) {
      getSeller();
      //Check if the access token has the ID. If not, request a new one
      const decoded = jwt_decode(accessToken);

      const sellerId = decoded["https://auth.vulkaza.com/seller_gcmsId"];
      if (!sellerId) {
        getAccessTokenSilently({ ignoreCache: true })
          .then((accessToken) => {
            setAccessToken(accessToken);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [id]);

  React.useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode(accessToken);

      const sellerId = decoded["https://auth.vulkaza.com/seller_gcmsId"];

      //Add the access token to the apollo client header
      const httpLink = createHttpLink({
        uri: VULKAZA_API_URL + "/api/graphql",
      });

      const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
          },
        };
      });

      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
      });

      setApolloClient(client);

      if (sellerId) {
        //Set up the user context if the user ID is in the token
        setId(sellerId);
      } else {
        //If the ID is not in the token, create a new user (but only once)
        console.log("ID was not in the token. Creating user acct.");

        //Set the flag to trigger the query on next update (so the client gets the new access token)
        setCreateSeller(true);
      }
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (createdSeller) {
      createSeller({
        variables: {
          input: {
            firstname: user.given_name ? user.given_name : "",
            lastname: user.family_name ? user.family_name : "",
            contactEmail: user.email,
          },
        },
      });
    }
  }, [createdSeller]);

  //Fetch the user when the ID is set
  React.useEffect(() => {
    if (getSellerResult?.data?.seller?.id) {
      setSeller(getSellerResult.data.seller);
    }
  }, [getSellerResult]);

  React.useEffect(() => {
    const getAccessToken = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      } catch (e) {
        console.error(e.message);
      }
    };

    if (user) {
      getAccessToken();
    }
  }, [getAccessTokenSilently, user?.sub]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <UserContext.Provider value={userContext}>
        <Router history={history}>
          <div id="app" className="d-flex flex-column h-100">
            <RouteChangeTracker />
            <NavBar />
            <Container
              className="flex-grow-1 px-0"
              style={{ maxWidth: "none" }}
            >
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/profile" component={Profile} />
                <Route path="/my-collection" component={MyProducts} />
                <Route path="/my-orders" component={MyOrders} />
                <Route path="/submit-order" component={SubmitOrder} />
                <Route path="/product/:productId" component={ProductPage} />
                <Route path="/order/:orderId" component={OrderPage} />
              </Switch>
            </Container>
            <Footer />
          </div>
        </Router>
      </UserContext.Provider>
    </ApolloProvider>
  );
};

export default App;
