import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";

import ProductView from "../components/ProductView";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ApolloConsumer } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import { useMutation, useQuery, gql } from "@apollo/client";

export const ProductPage = ({ match }) => {
  React.useEffect(() => (document.title = "Product - Vulkaza Dashboard"));

  const {
    params: { productId },
  } = match;

  return (
    <Container>
      <Col>
        <Row>
          <Col>
            <p style={{ textAlign: "center", width: "100%" }}>
              <span
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Step 3:
              </span>{" "}
              To start selling this product, simply download and upload the
              images and copy product description directly to your e-shop.
            </p>
            <p style={{ textAlign: "center", width: "100%" }}>
              Once your customers place orders, report orders{" "}
              <a href="/submit-order">here</a>.
            </p>
          </Col>
        </Row>
        <Row>
          <ApolloConsumer>
            {(client) => <ProductView client={client} productId={productId} />}
          </ApolloConsumer>
        </Row>
      </Col>
    </Container>
  );
};

export default withAuthenticationRequired(ProductPage, {
  onRedirecting: () => <Loading />,
});
