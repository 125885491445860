import React from "react";

import { useLocation } from "react-router-dom";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Col,
  Row,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Alert,
  Badge,
} from "reactstrap";
import Loading from "./Loading";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import reactRouterDom from "react-router-dom";

import formatToCurrency from "../utils/currentyFormatter";
import { codeToCountry } from "../utils/countryCodes";

import ErrorView from "./ErrorView";

const GET_ORDER = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      createdAt
      deadline
      status
      products {
        product {
          sku
          name
          price {
            currency
            value
          }
          components {
            name
            colorSections {
              name
              colorOptions {
                name
              }
            }
          }
        }
        quantity
      }
      deliveryAddress {
        name
        postcode
        addressLines
        city
        state
        country
        phoneNumber
      }
    }
  }
`;

//Rendered line in the table
const LineItem = (props) => {
  const orderLine = props.orderLine;

  const colorNames = orderLine.product.components.map((component) =>
    component.colorSections.length > 0
      ? component.colorSections[0].colorOptions[0]?.name
      : ""
  );
  var colors = colorNames.join(", ");

  return (
    <tr>
      <td>{orderLine.product.name}</td>
      <td>{colors}</td>
      <td>{orderLine.product.sku}</td>
      <td className="text-right">{orderLine.quantity}</td>
      <td className="text-right font-weight-bold">
        {formatToCurrency(
          orderLine.product.price.value * orderLine.quantity,
          orderLine.product.price.currency
        )}
      </td>
    </tr>
  );
};

const DeliveryAddress = (props) => {
  const a = props.deliveryAddress;
  return (
    <Col className="mx-2">
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          Name
        </Col>
        <Col>{a.name}</Col>
      </Row>
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          Address
        </Col>
        <Col>{a.addressLines.split("\n").concat()}</Col>
      </Row>
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          Country
        </Col>
        <Col>{codeToCountry(a.country)}</Col>
      </Row>
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          State
        </Col>
        <Col>{a.state ? a.state : "-"}</Col>
      </Row>
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          City
        </Col>
        <Col>{a.city}</Col>
      </Row>
      <Row>
        <Col
          className="font-weight-bold px-0 flex-grow-0"
          style={{ minWidth: "100px" }}
        >
          Phone
        </Col>
        <Col>{a.phone ? a.phone : "-"}</Col>
      </Row>
    </Col>
  );
};

const PaymentBadge = (props) => {
  var text = "";
  var color = "primary";

  switch (props.paymentStatus) {
    case "not_initiated":
      text = "No payment registered";
      color = "warning";
      break;
    case "processing":
      text = "Processing payment";
      color = "warning";
      break;
    case "paid":
      text = "Payment successful";
      color = "success";
      break;
  }

  return <Badge color={color}>{text}</Badge>;
};

const OrderView = (props) => {
  const [orderData, setOrderData] = React.useState(null);
  const [lineItemRows, setLineItemRows] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState(0);

  const [alert, setAlert] = React.useState({ visible: false, message: "" });

  const [errorType, setErrorType] = React.useState("");
  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const maxRefetchAttempts = 10;
  const [refetchCounter, setRefetchCounter] = React.useState(0);
  const orderQuery = useQuery(GET_ORDER, {
    variables: { id: props.orderId },
  });

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  //Handle the order fetching
  React.useEffect(() => {
    if (orderQuery.data && !orderData) {
      const o = orderQuery.data?.order;
      if (o) {
        //Add each registered line to the table
        setLineItemRows(
          o.products.map((p, index) => <LineItem orderLine={p} key={index} />)
        );

        //Calcualte the total price
        var total = 5; //This is the cost of shipping.

        o.products.forEach((li) => {
          total += li.quantity * li.product.price.value;
        });
        setTotalPrice(total);
        setOrderData(o);
      }
    } else if (orderQuery.error) {
      //If something went wrong, it's because the order isn't published, or because the ID is wrong
      //If the order isn't published, it's either because the payment failed, or because the webhook catcher hasn't run yet
      //First check if the URL params has the redirect_status param, which indicates you were redirected
      //here from placing an order
      const redirect_status = urlParams.get("redirect_status");
      if (redirect_status) {
        //Check if the payment was a success
        if (redirect_status === "succeeded") {
          //If it was, try re-fetching the order a couple of times while showing a relevant message
          setErrorType("LOADING");
          setErrorTitle("Creating order");
          setErrorMessage("Please wait while we create your order!");
          console.log(refetchCounter);
          if (refetchCounter < maxRefetchAttempts) {
            console.log("refetching order");
            orderQuery.refetch();
            setRefetchCounter(refetchCounter + 1);
          } else {
            //If it was not, tell the user that something is wrong
            setErrorType("ERROR");
            setErrorTitle("Something went wrong");
            setErrorMessage("Something went wrong creating your order.");
          }
        } else {
          //If it was not, tell the user that something is wrong
          setErrorType("ERROR");
          setErrorTitle("Payment failed");
          setErrorMessage("Something went wrong during the payment process.");
        }
      } else {
        //If it doesn't have a redirect_status, tell the user that this order does not seem to exist, and to contact Vulkaza with the order ID if they think something is wrong
        setErrorType("ERROR");
        setErrorTitle("Can't find this order");
        setErrorMessage(
          "We are unable to find the order with ID " + props.orderId
        );
      }
    }
  }, [orderQuery]);

  //Handle removing the alert
  React.useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setAlert({ ...alert, visible: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  return !orderQuery.loading ? (
    orderData?.id ? (
      <Col>
        {alert.visible && <Alert color="success">{alert.message}</Alert>}
        <Row>
          <Col>
            <h1 className="mt-5">Order for {orderData.deliveryAddress.name}</h1>
            <p>Order ID: {orderData.id}</p>
            <p>Fulfilment status: {orderData.status}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="mt-5">Delivery Address</h3>
            <DeliveryAddress deliveryAddress={orderData.deliveryAddress} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="mt-5">Order Lines</h3>
            <Table striped>
              <tbody>
                {lineItemRows}
                <tr>
                  <td colSpan={3} className="text-left ">
                    Local Shipping (flat rate)
                  </td>
                  <td colSpan={1} className="text-right ">
                    1
                  </td>
                  <td className="text-right font-weight-bold">
                    {formatToCurrency(5, "USD")}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-left font-weight-bold">
                    Total
                  </td>
                  <td className="text-right font-weight-bold">
                    {formatToCurrency(totalPrice, "USD")}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    ) : (
      <ErrorView type={errorType} title={errorTitle} message={errorMessage} />
    )
  ) : (
    <Loading />
  );
};

export default OrderView;
