import React from "react";

import placeholder from "../assets/image_placeholder.png";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Col,
  Row,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
  Tooltip,
} from "reactstrap";
import Loading from "./Loading";
import FloatingSavePopup from "./FloatingSavePopup";
import ColorBox from "./ColorBox";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import reactRouterDom from "react-router-dom";

import formatToCurrency from "../utils/currentyFormatter";
import formatImageUrl from "../utils/gcmsImageResize";
import { generateAndDownloadZipDirectoryFromURLs } from "../utils/fileZipper";

const GET_PRODUCT = gql`
  query ($id: ID!) {
    product(id: $id) {
      id
      name
      description
      sku
      price {
        currency
        value
      }
      images {
        url
        fileName
      }
      components {
        id
        name
        colorSections {
          colorOptions {
            name
            hexCode
          }
        }
      }
    }
    products {
      id
      sku
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation ($id: ID!, $data: ProductUpdateInput!) {
    updateProduct(id: $id, data: $data) {
      id
      name
      description
      sku
      price {
        currency
        value
      }
      images {
        url
        fileName
      }
      components {
        id
        name
        colorSections {
          colorOptions {
            name
            hexCode
          }
        }
      }
    }
  }
`;

const ImagePaginator = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: 300,
      }}
    >
      {props.images.map((img, index) => (
        <img
          src={props.images[index].url}
          width="60"
          height="60"
          style={{ opacity: props.currentImage == index ? 1 : 0.5 }}
          key={index}
          onClick={() => {
            props.setCurrentImage(index);
          }}
        />
      ))}
    </div>
  );
};

const ComponentOverview = (props) => {
  return (
    <div
      className="d-flex flex-column"
      style={{
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: 4,
        borderColor: "#ddd",
      }}
    >
      {props.components.map((component, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 4,
          }}
          key={index}
        >
          <p style={{ margin: 0, marginRight: 32 }}>{component.name}</p>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            {component.colorSections.length > 0 &&
              component.colorSections[0].colorOptions.map((color, index) => (
                <ColorBox
                  name={color.name}
                  hexCode={color.hexCode}
                  key={index}
                  id={"ColorBox_" + component.id + "_" + index}
                  showHexCodeInTooltip={true}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductView = (props) => {
  const [productData, setProductData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [sku, setSKU] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [existingSKUs, setExistingSKUs] = React.useState([]);
  const [alert, setAlert] = React.useState({ visible: false, message: "" });
  const [currentImage, setCurrentImage] = React.useState(0);
  const [imageViewMode, setImageViewMode] = React.useState("cover");

  const [updateProduct, { data, loading, error }] = useMutation(UPDATE_PRODUCT);
  const productQuery = useQuery(GET_PRODUCT, {
    variables: { id: props.productId },
  });

  const changesMade = () => {
    return (
      name !== productData.name ||
      description !== productData.description ||
      sku !== productData.sku
    );
  };

  const isValid = () => {
    return name !== "" && sku !== "";
  };

  const revertChanges = () => {
    setName(productData.name);
    setSKU(productData.sku);
    setDescription(productData.description ? productData.description : "");
  };

  const saveChanges = () => {
    updateProduct({
      variables: { id: props.productId, data: { name, sku, description } },
    });
  };

  const toggleImageViewMode = () => {
    setImageViewMode(imageViewMode === "cover" ? "scale-down" : "cover");
  };

  React.useEffect(() => {
    if (productQuery.data && !productData) {
      const p = productQuery.data?.product;
      if (p) {
        setProductData(p);
        setName(p.name);
        setSKU(p.sku);
        setDescription(p.description ? p.description : "");
        setLoaded(true);
      }
      if (productQuery.data?.products) {
        var allProducts = productQuery.data.products;

        const otherProducts = allProducts.filter(
          (product) => product.id != p.id
        );

        const otherSKUs = otherProducts.map((p) => {
          return p.sku;
        });

        setExistingSKUs(otherSKUs);
      }
    }
  }, [productQuery]);

  React.useEffect(() => {
    if (data?.updateProduct?.id) {
      const p = data?.updateProduct;
      setProductData(p);
      setName(p.name);
      setSKU(p.sku);
      setDescription(p.description ? p.description : "");
      setAlert({ visible: true, message: "Changes saved!" });
    }
  }, [data]);

  React.useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setAlert({ ...alert, visible: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  const skuExists = existingSKUs.includes(sku);

  return productData ? (
    <Col>
      {/*alert.visible && <Alert color="success">{alert.message}</Alert>*/}
      <div className="d-flex row">
        <div className="flex-grow-1 text-center">
          <img
            alt={
              productData.images.length > 0
                ? "Product Image " + productData.images[currentImage].filename
                : "Missing image"
            }
            src={
              productData.images.length > 0
                ? formatImageUrl(productData.images[currentImage].url, 500, 500)
                : placeholder
            }
            width="300px"
            height="300px"
            style={{ objectFit: imageViewMode }}
            onClick={toggleImageViewMode}
          />
          <div className="d-flex justify-content-center mt-1">
            {productData.images.length > 1 && (
              <ImagePaginator
                images={productData.images}
                currentImage={currentImage}
                setCurrentImage={setCurrentImage}
              />
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={() => {
                generateAndDownloadZipDirectoryFromURLs(
                  productData.name,
                  productData.images
                );
              }}
            >
              Download all images
            </Button>
          </div>
        </div>
        <Form style={{ flexGrow: 4 }}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              value={name}
              type="text"
              placeholder="The name of this product"
              onChange={(e) => setName(e.target.value)}
              invalid={name === ""}
            />
            <FormFeedback>{"The name can't be blank."}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Cost per item</Label>
            <Input
              value={formatToCurrency(
                productData.price.value,
                productData.price.currency
              )}
              contentEditable="false"
              disabled
              type="text"
              style={{
                textAlign: "right",
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>SKU</Label>
            <Input
              value={sku}
              type="text"
              placeholder="The unique SKU of this product"
              onChange={(e) => setSKU(e.target.value)}
              invalid={skuExists || sku === ""}
            />
            <FormFeedback>
              {skuExists
                ? "This SKU is used by one of your other products."
                : "The SKU can't be blank."}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Available Colors</Label>
            <ComponentOverview components={productData.components} />
          </FormGroup>

          <FormGroup>
            <Label>Description</Label>
            <Input
              value={description}
              type="textarea"
              placeholder="A descriptive text for this product"
              onChange={(e) => setDescription(e.target.value)}
              rows="5"
            />
          </FormGroup>
        </Form>
      </div>
      {changesMade() && (
        <FloatingSavePopup
          revertChanges={revertChanges}
          saveChanges={saveChanges}
          disabled={loading || !isValid()}
        />
      )}
    </Col>
  ) : (
    <Loading />
  );
};

export default ProductView;
