import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const ModalBanner = (props) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <div style={style}>
      <p className="h5 m-2">{props.header}</p>
      <Button
        className="m-2"
        color="secondary"
        onClick={() => setModalVisible(true)}
      >
        {props.buttonText}
      </Button>
      {props.modal(modalVisible, () => setModalVisible(!modalVisible))}
    </div>
  );
};

const style = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  //width: "90%",
  backgroundColor: "#85FFC4",
  padding: "16px",
  //borderRadius: "0px 16px 16px 0px",
  //marginTop: "16px",
};

export default ModalBanner;
