import React, { Component } from "react";

import placeholder from "../assets/image_placeholder.png";

import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

import contentData from "../utils/contentData";
import formatToCurrency from "../utils/currentyFormatter";
import formatImageUrl from "../utils/gcmsImageResize";

const DUPLICATE_PRODUCT = gql`
  mutation ($id: ID!) {
    duplicateProduct(data: { productId: $id }) {
      id
    }
  }
`;

const CatalogueItem = (props) => {
  const history = useHistory();

  const p = props.product;
  const [hover, setHover] = React.useState(false);

  const [duplicateProduct, { data, loading, error }] = useMutation(
    DUPLICATE_PRODUCT,
    { variables: { id: p.id } }
  );

  React.useEffect(() => {
    if (data?.duplicateProduct?.id) {
      let path = `/product/${p.id}`;
      history.push(path);
    }
  }, [data]);

  var imgsrc = placeholder;
  var imgalt = "Missing image";

  if (p.images.length > 0) {
    if (hover && p.images.length > 1) {
      imgsrc = formatImageUrl(p.images[1].url, 400, 400);
      imgalt = p.images[1].fileName;
    } else {
      imgsrc = formatImageUrl(p.images[0].url, 400, 400);
      imgalt = p.images[0].fileName;
    }
  }

  return (
    <Card
      style={{
        borderWidth: 0,
        width: "16rem",
      }}
    >
      <CardImg
        onMouseEnter={(event) => setHover(true)}
        onMouseLeave={(event) => setHover(false)}
        alt={imgalt}
        src={imgsrc}
        top
        style={{ objectFit: "cover", width: "16rem", height: "16rem" }}
      />
      <CardBody className="d-flex flex-column justify-content-between p-0">
        <CardTitle className="mx-2 mt-2 mb-0">{p.name}</CardTitle>
        <CardText className="h5 mx-2 mt-0">
          {formatToCurrency(p.price.value, p.price.currency)}
        </CardText>
        <div className="d-flex justify-content-center">
          <Button color="primary" onClick={() => props.duplicateProduct(p.id)}>
            Add to my collection
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default CatalogueItem;
