import React from "react";

import logo from "../assets/logo.svg";
import { useHistory, Link } from "react-router-dom";

import {
  Label,
  Input,
  Button,
  List,
  ListInlineItem,
  Row,
  Col,
  CardGroup,
  PlaceholderButton,
  Placeholder,
} from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import ProductCard from "./ProductCard";

const GET_PRODUCTS = gql`
  query ($sellerId: ID!) {
    seller(id: $sellerId) {
      products {
        id
        sku
        name
        description
        price {
          currency
          value
        }
        images {
          url
          fileName
        }
      }
    }
  }
`;

const NoProductsPlaceholder = () => {
  return (
    <Col>
      <Row className="my-4">
        <div className="h1 text-center w-100">
          This is where you would see your products.
        </div>
        <div className="h2 text-center w-100">If you had any.</div>
      </Row>
      <Row className="my-4">
        <p className="lead text-center w-100">
          Check out our <Link to={"/"}>product catalogue</Link> to find awesome
          3D printed products.
        </p>
      </Row>
    </Col>
  );
};

const ProductList = (props) => {
  const userContext = React.useContext(UserContext);

  const [productList, setProductList] = React.useState(null);

  const { loading, error, data, refetch } = useQuery(GET_PRODUCTS, {
    variables: { sellerId: userContext.id },
  });

  React.useEffect(() => {
    if (data) {
      const products = data?.seller?.products;
      if (products) {
        setProductList(products);
      }
    }
  }, [data]);

  var productCards = [];

  if (productList) {
    productCards = productList.map((product, index) => (
      <ProductCard product={product} onDuplicate={refetch} key={index} />
    ));
  }

  if (productList) {
    return productList.length > 0 ? (
      <div
        className="d-flex flex-wrap p-0 mx-3 mb-3 my-4"
        style={{ gap: "64px 16px" }}
      >
        {productCards}
      </div>
    ) : (
      <NoProductsPlaceholder />
    );
  } else {
    return (
      <Col>
        <div className="h1">Loading...</div>
      </Col>
    );
  }
};

export default ProductList;
