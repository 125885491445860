import React from "react";

import logo from "../assets/logo.svg";

import { Row, Col, Button, Card, Container, CardGroup } from "reactstrap";

import { UserContext } from "../contexts/UserContext";
import CatalogueItem from "./CatalogueItem";

const CatalogueList = (props) => {
  const userContext = React.useContext(UserContext);

  const productList = props.collection.products;

  var catalogueItems = [];

  if (productList) {
    catalogueItems = productList.map((product, index) => (
      <CardGroup className="p-0 mx-3 mb-3" key={index}>
        <CatalogueItem
          product={product}
          duplicateProduct={props.duplicateProduct}
        />
      </CardGroup>
    ));
  }

  return (
    <div className="m-0 row flex-nowrap overflow-auto">{catalogueItems}</div>
  );
};

export default CatalogueList;
