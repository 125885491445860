import React from "react";

import { useLocation, useHistory } from "react-router-dom";

import { Table } from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";
import formatToCurrency from "../utils/currentyFormatter";
import { codeToCountry, getFlagEmoji } from "../utils/countryCodes";

import TableHeaderSortingToggle from "./TableHeaderSortingToggle";

const GET_ORDERS = gql`
  query ($id: ID!) {
    seller(id: $id) {
      orders {
        id
        deadline
        createdAt
        status
        products {
          quantity
          product {
            name
            price {
              currency
              value
            }
          }
        }
        deliveryAddress {
          name
          country
        }
      }
    }
  }
`;

const OrderList = (props) => {
  const history = useHistory();
  const userContext = React.useContext(UserContext);

  const [orderList, setOrderList] = React.useState(null);

  const [createdAtSort, setCreatedAtSort] = React.useState("ASC");

  const ordersQuery = useQuery(GET_ORDERS, {
    variables: { id: userContext.id },
    skip: !userContext.id,
  });

  const openOrderPage = (id) => {
    let path = `/order/${id}`;
    history.push(path);
  };

  React.useEffect(() => {
    if (ordersQuery.data) {
      const orders = ordersQuery.data?.seller?.orders;
      if (orders) {
        setOrderList(orders);
      }
    }
  }, [ordersQuery]);

  const headerRow = (
    <thead>
      <tr>
        <TableHeaderSortingToggle
          label="Created"
          value={createdAtSort}
          setValue={setCreatedAtSort}
        />
        <th>Products</th>
        <th>Customer</th>
        <th>Deadline</th>
        <th>Status</th>
        <th>Cost</th>
      </tr>
    </thead>
  );

  var orderRows = [];

  const formatProductInfo = (products) => {
    var productElements = products.map((p, index) => (
      <p key={index}>{p.quantity + " " + p.product.name}</p>
    ));

    return <div>{productElements}</div>;
  };

  const orderPassesFilter = (order) => {
    if (props.filter === "") {
      return true;
    }

    //Check if the name matches
    if (
      order.deliveryAddress.name
        .toLowerCase()
        .includes(props.filter.toLowerCase())
    ) {
      return true;
    }

    //Check if the country matches
    if (
      codeToCountry(order.deliveryAddress.country).toLowerCase() ===
      props.filter.toLowerCase()
    ) {
      return true;
    }

    //Check if the country code
    if (
      order.deliveryAddress.country.toLowerCase() === props.filter.toLowerCase()
    ) {
      return true;
    }

    //Check if any of the products match
    var productMatch = false;
    order.products.forEach((orderedProduct) => {
      if (
        orderedProduct.product.name
          .toLowerCase()
          .includes(props.filter.toLowerCase())
      ) {
        productMatch = true;
      }
    });
    if (productMatch) {
      return true;
    }

    return false;
  };

  if (orderList) {
    if (orderList.length > 0) {
      var sortedList = orderList.slice().sort((a, b) => {
        if (createdAtSort == "DESC") {
          return 1;
        } else {
          return -1;
        }
      });

      var filteredList = sortedList.filter((order) => orderPassesFilter(order));

      orderRows = filteredList.map((order, index) => (
        <tr key={index} onClick={() => openOrderPage(order.id)}>
          <td>{new Date(order.createdAt).toLocaleDateString()}</td>
          <td>{formatProductInfo(order.products)}</td>
          <td>
            {getFlagEmoji(order.deliveryAddress.country) +
              " " +
              order.deliveryAddress.name}
          </td>
          <td>{new Date(order.deadline).toLocaleDateString()}</td>
          <td>{order.status}</td>
          <td>
            {formatToCurrency(
              order.products.reduce((previous, current) => {
                return (
                  previous + current.product.price.value * current.quantity
                );
              }, 0),
              "USD" //TODO: USE THE ACTUAL CURRENCY FROM THE ORDER
            )}
          </td>
        </tr>
      ));

      if (orderRows.length == 0) {
        orderRows = (
          <tr>
            <td className="text-center" colSpan="6">
              No orders match this filter.
            </td>
          </tr>
        );
      }
    } else {
      orderRows = (
        <tr>
          <td className="text-center" colSpan="6">
            Your orders will show up here.
          </td>
        </tr>
      );
    }
  } else {
    orderRows = (
      <tr>
        <td className="text-center" colSpan="6">
          Loading orders...
        </td>
      </tr>
    );
  }

  return (
    <Table hover striped>
      {headerRow}
      <tbody>{orderRows}</tbody>
    </Table>
  );
};

export default OrderList;
