import React from "react";

import logo from "../assets/logo.svg";

import {
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Placeholder,
  Alert,
} from "reactstrap";

import { useMutation, useQuery, gql } from "@apollo/client";
import { UserContext } from "../contexts/UserContext";

const UPDATE_SELLER = gql`
  mutation ($id: ID!, $input: SellerUpdateInput!) {
    updateSeller(id: $id, data: $input) {
      id
      firstname
      lastname
      company
      contactEmail
      phone
    }
  }
`;

const ProfileView = (props) => {
  const { seller, id, setSeller } = React.useContext(UserContext);

  const [firstname, setFirstname] = React.useState(
    seller ? seller.firstname : ""
  );
  const [lastname, setLastname] = React.useState(seller ? seller.lastname : "");
  const [company, setCompany] = React.useState(seller ? seller.company : "");
  const [email, setEmail] = React.useState(seller ? seller.contactEmail : "");
  const [phone, setPhone] = React.useState(seller ? seller.phone : "");
  const [alert, setAlert] = React.useState({ visible: false, message: "" });

  const [updateSeller, { data, loading, error }] = useMutation(UPDATE_SELLER);

  React.useEffect(() => {
    if (seller) {
      setFirstname(seller.firstname);
      setLastname(seller.lastname);
      setCompany(seller.company);
      setEmail(seller.contactEmail);
      setPhone(seller.phone);
    }
  }, [seller]);

  React.useEffect(() => {
    if (data?.updateSeller?.id) {
      setFirstname(data.updateSeller.firstname);
      setLastname(data.updateSeller.lastname);
      setCompany(data.updateSeller.company);
      setEmail(data.updateSeller.contactEmail);
      setPhone(data.updateSeller.phone);
      setAlert({ visible: true, message: "Changes saved!" });
    }
  }, [data]);

  //Timer for save confirmation alert
  React.useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setAlert({ ...alert, visible: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  const handleSubmit = (event) => {
    event.preventDefault();

    updateSeller({
      variables: { id: id, input: { firstname, lastname, company, phone } },
    });
  };

  const userHasEdited =
    seller?.firstname != firstname ||
    seller?.lastname != lastname ||
    seller?.company != company ||
    seller?.phone != phone;

  return (
    <Col>
      {alert.visible && <Alert color="success">{alert.message}</Alert>}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>First name</Label>
          <Input
            id="firstname"
            name="firstname"
            placeholder={!seller ? "Loading..." : "Your given name"}
            type="text"
            required
            value={firstname}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
            disabled={!seller}
          />
        </FormGroup>
        <FormGroup>
          <Label>Last name</Label>
          <Input
            id="lastname"
            name="lastname"
            placeholder={!seller ? "Loading..." : "Your family name"}
            type="text"
            required
            value={lastname}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
            disabled={!seller}
          />
        </FormGroup>
        <FormGroup>
          <Label>Company</Label>
          <Input
            id="company"
            name="company"
            placeholder={
              !seller ? "Loading..." : "The company you represent (optional)"
            }
            type="text"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            disabled={!seller}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            id="email"
            name="email"
            placeholder={!seller ? "Loading..." : "Your email address"}
            type="email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone</Label>
          <Input
            id="phone"
            name="phone"
            placeholder={
              !seller ? "Loading..." : "Your phone number (optional)"
            }
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            disabled={!seller}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-end">
          <Button
            color="primary"
            type="submit"
            id="SaveChangesButton"
            disabled={loading || !userHasEdited}
          >
            Save changes
          </Button>
        </FormGroup>
      </Form>
    </Col>
  );
};

export default ProfileView;
